
.stations-listing {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-right: -0.5em;

  .card-station {
    flex: 1 1 320px;

    margin-bottom: 0.5em;
    margin-right: 0.5em;
  }
}

.card-station {
  padding: 1em;

  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.5em;

  .flag {
    align-self: center;
    font-size: 16pt;
    margin: 0 0.25em;
  }

  .actions {
    min-width: 80px;

    .btn-group {
      width: 100%;
    }
  }

}
